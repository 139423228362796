<template>
  <div
    style="min-width: 100%"
    class="mass-add-shortlists-container"
    v-if="init"
  >
    ooooh
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "MassAddShortlists",
  data() {
    return {
      search: "",
      searchReplace: "",
    };
  },
  computed: {
    ...mapState({
      selectedProduct: (state) => state.massAddShortlists.selectedProduct,
      loading: (state) => state.massAddShortlists.loading,
      init: (state) => state.massAddShortlists.init,
    }),
    ...mapGetters("products", [
      "getProductByFirebaseId",
      "getProductsBySearch",
    ]),
    ...mapGetters("settings", ["getCompanyNameByVismaId"]),
    ...mapGetters("massEditShortlists", [
      "selectedCompanyShortlists",
      "selectedSharedShortlists",
      "getCompanyShortlistNameById",
      "getCompanyShortlistCompanyIdById",
      "getSharedShortlistNameInternalById",
    ]),
  },
  components: {},
  async created() {
    if (!this.init) {
      await this.$store.dispatch("companyShortlists/bindShortlists");
      await this.$store.dispatch("sharedShortlists/bindShortlists");
      this.$store.commit("massAddShortlists/setInit", true);
    }
  },
  async beforeDestroy() {
    this.$store.commit("massAddShortlists/setInit", false);
    await this.$store.dispatch("companyShortlists/unbindShortlists");
    await this.$store.dispatch("sharedShortlists/unbindShortlists");
  },
  methods: {},
};
</script>
